<template>
    <div class="game">
      <img :src="icon" alt="Game icon" class="game-icon" />
      <div class="game-info">
        <h3>{{ name }}</h3>
        <a :href="googlePlayLink" target="_blank" rel="noopener noreferrer">
          <img :src="googleBadge" class="store-badge"/>
        </a>
        <a :href="appleStoreLink" target="_blank" rel="noopener noreferrer">
          <img :src="appleBadge" class="store-badge"/>
        </a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "GamePage",
    props: {
      icon: String,
      name: String,
      googlePlayLink: String,
      appleStoreLink: String,
    },
    data() {
      return {
        googleBadge: require("../assets/google-play-badge.svg"),
        appleBadge: require("../assets/app-store-badge.svg"),
      };
    },
  };
  </script>
  
  <style scoped>
  .game {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .game-icon {
    border-radius: 10px;
    width: 150px;
    height: 150px;
    margin-right: 20px;
  }
  
  .store-badge {
    width: 130px;
    height: auto;
    margin-right: 10px;
  }
  </style>