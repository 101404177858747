<template>
  <div id="app">
    <h1>Game Portfolio</h1>
    <div class="game-list">
      <Game
        v-for="game in games"
        :key="game.id"
        :icon="game.icon"
        :name="game.name"
        :googlePlayLink="game.googlePlayLink"
        :appleStoreLink="game.appleStoreLink"
      />
    </div>
  </div>
</template>

<script>
import Game from "./components/GamePage.vue";

export default {
  name: "App",
  components: {
    Game,
  },
  data() {
    return {
      games: [
        {
          id: 1,
          icon: "https://play-lh.googleusercontent.com/v6KVl45Mtv11stWZxR7G7CYHSqovQY9cgeH3mwoDAuQthcxtsVeT_Y8ZPAzXN1BX3lQ=w480-h960-rw",
          name: "Scale Race",
          googlePlayLink: "https://play.google.com/store/apps/details?id=com.ninozalascek.ScaleRace",
          appleStoreLink: "https://apps.apple.com/us/app/scale-race/id1581286152",
        },
        {
          id: 2,
          icon: "https://play-lh.googleusercontent.com/v6KVl45Mtv11stWZxR7G7CYHSqovQY9cgeH3mwoDAuQthcxtsVeT_Y8ZPAzXN1BX3lQ=w480-h960-rw",
          name: "Scale Race",
          googlePlayLink: "https://play.google.com/store/apps/details?id=com.example.game1",
          appleStoreLink: "https://apps.apple.com/app/id123456789",
        },
        // Add more games here
      ],
    };
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.game-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>